import styles from './HuntingTime.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';


const HuntingTime = props => {
    return <div key={props.huntingTime.title}>
        <p>
            <h3 className={styles.title}>
                {props.huntingTime.title}
            </h3>
            <span className={styles['calendar-icon']}>
                <FontAwesomeIcon icon={faCalendarAlt} />
            </span>
            <span className={styles.start}>{props.huntingTime.start} - </span>
            <span className={styles.end}>{props.huntingTime.end}</span>
        </p>
    </div>
};

export default HuntingTime;