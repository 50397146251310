import Card from '../UI/Card';
import HuntingTime from './HuntingTime';
import styles from './HuntingTimesList.module.css';

const HuntingTimesList = props => {
    return <Card className={styles.card}>
        <h3 className={styles['hunting-time-title']}>{props.title}</h3>
        <div className={styles['hunting-times__section']}>
            {props.huntingTimes.map(huntingTime => {
                return <HuntingTime key={huntingTime.title} huntingTime={huntingTime} />
            })}
        </div>

    </Card>
};

export default HuntingTimesList;