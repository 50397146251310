import styles from './ContactPerson.module.css';
import { faPhone, faEnvelope, faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '../UI/Card';

const ContactPerson = props => {

    const callablePhone = `tel:${[props.contact.phonenumber]}`;
    const sendableEmail = `mailto:${props.contact.email}`;
    const addressName = props.contact.address !== undefined ? props.contact.address.name : '';
    const addressHref = props.contact.address !== undefined ? props.contact.address.href : '';


    const phoneContent = <li>
        <a href={callablePhone} >
            <span className={styles['phone-icon']}>
                <FontAwesomeIcon icon={faPhone} />
            </span>
            <span className={styles.phonenumber}>{props.contact.phonenumber}</span>
        </a>
    </li>

    const emailContent = <li>
        <a href={sendableEmail}>
            <span className={styles['email-icon']}>
                <FontAwesomeIcon icon={faEnvelope} />
            </span>
            <span className={styles.email}>{props.contact.email}</span>
        </a>
    </li>

    const addressContent = <li>
        <a href={addressHref}>
            <span className={styles['address-icon']}>
                <FontAwesomeIcon icon={faMapMarker} />
            </span>
            <span className={styles.address}>{addressName}</span>
        </a>
    </li>

    return <Card className={props.className} >
        <h3 className={styles.person}>{props.contact.person}</h3>
        <ul>
            {props.contact.phonenumber !== undefined && phoneContent}
            {props.contact.email !== undefined && emailContent}
            {addressName !== '' && addressContent}
        </ul>
    </Card>
};

export default ContactPerson;