import { Fragment } from 'react';
import styles from './PriceTable.module.css';

const PriceTable = (props) => {
    return <Fragment>
        <table className={`${props.className} ${styles['price-table']} `}>
            <thead>
                <tr>
                    {props.headerItems.map(item => {
                        return <th key={item}>{item}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {props.items.map(item => {
                    return <tr key={item.weight}>
                        <td>{item.weight}</td>
                        <td>{item.price}</td>
                        {item.additional && <td>{item.additional}</td>}
                    </tr>
                })}
            </tbody>
        </table>
    </Fragment>
};

export default PriceTable;