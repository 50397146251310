import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Contact from "./pages/Contact";
import GuestHouse from "./pages/GuestHouse";
import MainPage from "./pages/MainPage";
import Territory from "./pages/Territory";
import Prices from "./pages/Prices";
import Conditions from "./pages/Conditions";
import Documents from "./pages/Documents";


function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/territory" element={<Territory />} />
        <Route path="/prices" element={<Prices />} />
        <Route path="/conditions" element={<Conditions />} />
        <Route path="/guest-house" element={<GuestHouse />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Layout>
  );
}

export default App;
