import Intro from "../components/Intro/Intro";
import { useContext } from "react";
import LanguageContext from "../context/language-context";

function MainPage() {

    const {language} = useContext(LanguageContext);

    const intro = {
        'hu': {
            'title': 'Köszöntjük Önt a Honlapunkon!',
            'description': '',
            'trophyReview': 'Vadásztársaságunk az eredményes gímszarvas bika vadászat után, 2023.10.07-én 11 órai kezdettel trófea szemlét tartott Aradon a Vadászháznál.'
        },
        'en': {
            'title': 'Welcome to our website!',
            'description': '',
            'trophyReview': `After the successful red deer stag hunting, our hunting association held a trophy evaluation on October 7, 2023, starting at 11 o'clock at the Hunter's Lodge in Arad.`
        },
        'de': {
            'title': 'Willkommen auf unserer Webseite!',
            'description': '',
            'trophyReview': `Nach einer erfolgreichen Jagd auf den Rothirschbock hat unser Jagdverein am 7. Oktober 2023 ab 11 Uhr eine Trophäenbewertung in der Jägerhütte in Arad durchgeführt.`
        }
    };
    return <section>
        <Intro intro={intro[language]} />
    </section>
}

export default MainPage;