import { useContext, useState } from 'react';
import LanguageContext from '../../../context/language-context';
import Background from '../../UI/Background';
import Title from '../../UI/Title';
import styles from './GuesthouseContent.module.css';
import PriceTable from '../../Prices/PriceTable';
import { ColorRing } from 'react-loader-spinner';

import ImageGallery from 'react-image-gallery';


const GuesthouseContent = props => {

    const { language } = useContext(LanguageContext);

    const prices = {
        roomPrice: 30,
        singleBedExtra: 8,
        breakfast: 15,
        lunch: 25,
        dinner: 25,
        car: 0.8
    };

    const content = {
        hu: {
            title: 'Vendégház',
            h2: 'Szállás ellátás',
            p1: `A II. kategóriába sorolt kétágyas szálláshelyen ${prices.roomPrice} €/éjszaka/fő áron tudunk szállást biztosítani. Egyágyas felár: €${prices.singleBedExtra}`,
            h2_2: 'Étkezési lehetőség',
            priceHeader_1: 'Étkezés',
            priceHeader_2: '€ / nap',
            foodPrices: [
                {
                    weight: 'Reggeli',
                    price: `${prices.breakfast}`,
                },
                {
                    weight: 'Ebéd',
                    price: `${prices.lunch}`,
                },
                {
                    weight: 'Vacsora',
                    price: `${prices.dinner}`,
                }
            ],
            h2_3: `Trófea előkészítése bírálatra, bírálati díj 50€`,
            h2_4: 'Galéria'
        },
        en: {
            title: 'Guesthouse',
            h2: 'Accommodation service',
            p1: `We can provide the II. level double bed accommodation at a price of ${prices.roomPrice} €/night/person. Surcharge for single bed: €${prices.singleBedExtra}`,
            h2_2: 'Dining options',
            priceHeader_1: 'Dining',
            priceHeader_2: '€ / day',
            foodPrices: [
                {
                    weight: 'Breakfast',
                    price: `${prices.breakfast}`,
                },
                {
                    weight: 'Lunch',
                    price: `${prices.lunch}`,
                },
                {
                    weight: 'Dinner',
                    price: `${prices.dinner}`,
                }
            ],
            h2_3: 'Trophy preparation for judging, judging fee €50'
        },
        de: {
            title: 'Gasthaus',
            h2: 'Zimmervermittlung',
            p1: `Die II. Unterkunft mit Doppelbetten können wir zum Preis von ${prices.roomPrice} €/Nacht/Person zur Verfügung stellen. Aufpreis für Einzelbett: €${prices.singleBedExtra}`,
            h2_2: 'Speisemöglichkeiten',
            priceHeader_1: 'Essen',
            priceHeader_2: '€ / tag',
            foodPrices: [
                {
                    weight: 'Frühstück',
                    price: `${prices.breakfast}`,
                },
                {
                    weight: 'Mittagessen',
                    price: `${prices.lunch}`,
                },
                {
                    weight: 'Abendessen',
                    price: `${prices.dinner}`,
                }
            ],
            h2_3: `Vorbereitung der Trophäe zur Bewertung, Bewertungsgebühr 50 €`
        }
    };

    const contentByLanguage = content[language];

    const images = [{
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_1.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_1.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902'
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_2.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_2.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902',
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_3.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_3.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902'
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_4.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_4.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902'
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_5.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_5.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902'
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_6.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_6.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902'
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_7.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_7.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902'
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_8.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_8.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902'
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_9.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_9.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902'
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_10.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/vadaszhaz%2Fvadaszhaz_10.jpeg?alt=media&token=d9741bfb-77b5-4cba-bfe0-43388c26c902'
    }];

    const [loading, setLoading] = useState(true);

    const onImageLoad = () => {
        setLoading(false)
    };

    return <Background>
        <Title title={contentByLanguage.title} />
        <div className={styles['guest-house']}>
            <h2>{contentByLanguage.h2}</h2>
            <p className={styles.rooms}>{contentByLanguage.p1}</p>
            <h2>{contentByLanguage.h2_2}</h2>
            <PriceTable className={styles['food']} headerItems={[contentByLanguage.priceHeader_1, contentByLanguage.priceHeader_2]} items={contentByLanguage.foodPrices} />
            <h3>{contentByLanguage.h2_3}</h3>
            {/* <h1>{contentByLanguage.h2_4}</h1> */}
            <div className={styles['spinner-container']} style={{ display: loading ? "block" : "none" }}>
                <ColorRing />
            </div>
            <div className={styles['image-gallery-wrapper']} style={{
                display: loading ? "none" : "block"
            }}>
                <ImageGallery
                    items={images}
                    showPlayButton={false}
                    onImageLoad={onImageLoad}
                />
            </div>
        </div>
    </Background >
};

export default GuesthouseContent;