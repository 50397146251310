import DocumentsContent from "../components/contents/DocumentsContent/DocumentsContent";

function Documents() {
    

    return <section>
        <DocumentsContent />
    </section>
}

export default Documents;