import Card from '../UI/Card';
import File from './File';
import styles from './FilesList.module.css';

const FilesList = props => {
    return <Card className={styles.card}>
        <h2>{props.title}</h2>
        <ul className={styles['file-list']}>
            {props.files.map(file => {
                return <li key={file.href}>
                    <File href={file.href} download={file.download} />
                </li>
            })}
        </ul>
    </Card>
};

export default FilesList;