import { Fragment } from "react";
import Footer from "../Footer/Footer";
import MainNavigation from "../MainNavigation/MainNavigation";
import MainBody from "./MainBody";

function Layout(props) {
    return <Fragment>
        <MainNavigation />
        <MainBody>{props.children}</MainBody>
        <Footer />
    </Fragment>;
}

export default Layout;