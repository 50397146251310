import styles from './Intro.module.css';
import Background from '../UI/Background';
import Title from '../UI/Title';
import { ColorRing } from 'react-loader-spinner';
import { useState } from 'react';
import ReactPlayer from 'react-player'

import ImageGallery from 'react-image-gallery';

function Intro(props) {

    const [loading, setLoading] = useState(true);

    const onImageLoad = () => {
        setLoading(false)
    };

    const trophyImages = [{
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F1.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F1.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711'
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F2.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F2.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711'
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F3.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F3.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711'
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F4.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F4.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711'
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F5.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F5.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711'
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F6.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F6.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711'
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F7.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F7.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711'
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F8.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F8.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711'
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F9.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F9.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711'
    }, {
        original: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F10.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711',
        thumbnail: 'https://firebasestorage.googleapis.com/v0/b/hunter-380019.appspot.com/o/trofeaszemle2023%2F10.JPG?alt=media&token=a352d7a9-02c9-4479-84f0-0136d448e711'
    },];


    return <Background>
        <div className={styles.intro}>
            <Title title={props.intro.title} />
            <div className={styles.description}>
                <p>{props.intro.description}</p>
            </div>
            <div className={styles.review}>
                <h3>{props.intro.trophyReview}</h3>
                <div className={styles['spinner-container']} style={{ display: loading ? "block" : "none" }}>
                    <ColorRing />
                </div>
                <div className={styles['image-gallery-wrapper']} style={{
                    display: loading ? "none" : "block"
                }}>
                    <ImageGallery
                        items={trophyImages}
                        showPlayButton={false}
                        onImageLoad={onImageLoad}
                    />
                </div>
            </div>
            <div className={styles.videos}>
                <ReactPlayer url='https://www.youtube.com/watch?v=GfCrh6u1FkE' controls={true} />

            </div>
            <div className={styles.videos}><ReactPlayer url='https://www.youtube.com/watch?v=CMdyrNKsbpI' controls={true} /></div>
            <div className={styles.videos}><ReactPlayer url='https://www.youtube.com/watch?v=1M39uuHQfi0' controls={true} /></div>
        </div>
    </Background>;
}

export default Intro;