import styles from './Background.module.css';

const Background = props => {

    const backgroundStyle = {
        background: 'white',
        // height: props.height || '80vh',   
    }

    return <div style={backgroundStyle} className={styles.background}>
        {props.children}
    </div>
};

export default Background