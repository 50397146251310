import styles from './File.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faDownload } from '@fortawesome/free-solid-svg-icons';

const File = props => {

    const fileWithoutExtension = props.download.split('.').slice(0, -1).join('.')

    return <a href={props.href} download={props.download}>
        <span className={styles['file-icon']}>
            <FontAwesomeIcon icon={faFile} />
        </span>
        <span className={styles['file-name']}>{fileWithoutExtension}</span>
        <span className={styles['download-icon']}>
            <FontAwesomeIcon icon={faDownload} />
        </span>
    </a>
};

export default File;