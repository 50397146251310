import React, { useContext, useState } from 'react';
import Select from 'react-select';
import LanguageContext from '../../context/language-context';
import styles from './LanguageSelector.module.css';

const LanguageSelector = props => {
    const languageOptions = [
        {
            value: 'hu',
            icon: 'hu.svg'
        },
        {
            value: 'en',
            icon: 'gb.svg'

        },
        {
            value: 'de',
            icon: 'de.svg'
        }
    ];

    const { language, setLanguage } = useContext(LanguageContext);
    const defaultLanguage = languageOptions.find(item => item.value === language);

    const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

    // handle onChange event of the dropdown
    const handleChange = event => {
        setSelectedLanguage(event);
        setLanguage(event.value);

        window.localStorage.setItem('selected-language', event.value);

        // Close the hamburger menu if the language is selected (clicked)
        props.onCloseHambrugerMenu();
    }

    return (
        <div className="App">
            <Select
                placeholder="Select Option"
                className={styles['react-select-container']}
                value={selectedLanguage}
                options={languageOptions}
                onChange={handleChange}
                isSearchable={false}
                getOptionLabel={e => (
                    <img src={e.icon} alt={e.value} height="25" width="25" id={e.id} className={styles['language__options']}/>
                )}
            />
        </div>
    );
}

export default LanguageSelector;