import { useContext } from 'react';
import LanguageContext from '../../../context/language-context';
import ContactPerson from '../../Contacts/ContactPerson';
import Background from '../../UI/Background';
import Title from '../../UI/Title';
import styles from './ContactContent.module.css';

const ContactContent = props => {

    const { language } = useContext(LanguageContext);

    const content = {
        hu: {
            title: 'Kapcsolat',
            president: 'Elnök',
            hunter: 'Hivatásos Vadász',
            hunterHouse: 'Vadászház'
        },
        en: {
            title: 'Contact',
            president: 'President',
            hunter: 'Professional Hunter',
            hunterHouse: 'Hunter House'

        },
        de: {
            title: 'Beziehung',
            president: 'Präsident',
            hunter: 'Berufsjäger',
            hunterHouse: 'Jagdschloss'
        }
    };

    const contentByLanguage = content[language];

    const contacts = [{
        person: `${contentByLanguage.hunterHouse}`,
        phonenumber: '+3685322647',
        email: 'info@vadasztarsasag-tapsony.hu',
        address: {
            name: 'Szenyér, C9XR+J8, 8717',
            href: 'https://goo.gl/maps/YwBfqVc3ETPqdQ8f7'
        }
    }, {
            person: `Meiszterics János ${contentByLanguage.president}`,
            phonenumber: '+36309472666'
        }, {
            person: `Gelencsér Gábor ${contentByLanguage.hunter}`,
            phonenumber: '+36302271475'
        }, {
            person: `Lancz Tamás ${contentByLanguage.hunter}`,
            phonenumber: '+36302271474'
        }
    ]

    return <Background>
        <Title title={content[language].title} />
        {contacts.map(contact => {
            return <ContactPerson contact={contact} key={contact.person} className={styles.card} />
        })}

    </Background>
};

export default ContactContent;