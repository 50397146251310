import { useContext } from 'react';
import LanguageContext from '../../../context/language-context';
import FilesList from '../../Files/FilesList';
import Background from '../../UI/Background';
import Title from '../../UI/Title';

import Rules from '../../../assets/documents/Alapszabaly.pdf';
import DisciplinaryRegulations from '../../../assets/documents/Fegyelmi Szabalyzat.pdf';
import HouseRules from '../../../assets/documents/Hazi Szabalyzat.pdf'

const DocumentsContent = props => {

    const {language} = useContext(LanguageContext);

    const content = {
        hu: {
            title: 'Dokumentumok',
            documentsTitle: 'Letölthető Társasági dokumentumok!',
            rules: 'Alapszabály',
            disciplinaryRegulations: 'Fegyelmi Szabályzat',
            houseRules: 'Házi Szabályzat'
        },
        en: {
            title: 'Documents',
            documentsTitle: 'Downloadable Company documents (in Hungarian)!',
            rules: 'Rules',
            disciplinaryRegulations: 'Disciplinary Regulations',
            houseRules: 'House Rules'
        },
        de: {
            title: 'Unterlagen',
            documentsTitle: 'Herunterladbare Unternehmensdokumente (in Ungarn)!',
            rules: 'Regeln',
            disciplinaryRegulations: 'Disziplinarordnung',
            houseRules: 'Hausordnung'
        }
    };

    const contentByLanguage = content[language]

    const files = [
        {
            href: Rules,
            download: `${contentByLanguage.rules}.pdf`
        }, {
            href: DisciplinaryRegulations,
            download: `${contentByLanguage.disciplinaryRegulations}.pdf`
        }, {
            href: HouseRules,
            download: `${contentByLanguage.houseRules}.pdf`
        }
    ];
    
    return <Background>
        <Title title={contentByLanguage.title} />
        <FilesList title={contentByLanguage.documentsTitle} files={files}/>
    </Background>
};

export default DocumentsContent