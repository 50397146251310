import { createContext, useState } from "react";

const LanguageContext = createContext({
    language: 'hu',
    setLanguage: () => { }
});

export const LanguageContextProvider = (props) => {
    const defaultSelectedLanguage = window.localStorage.getItem('selected-language') || 'hu';

    const [language, setLanguage] = useState(defaultSelectedLanguage);

    return <LanguageContext.Provider
        value={{
            language: language,
            setLanguage: setLanguage
        }}>
        {props.children}
    </LanguageContext.Provider>
};

export default LanguageContext;