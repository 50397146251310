import { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import LanguageContext from '../../context/language-context';
import Brand from '../Brand/Brand';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import ToggleButton from '../UI/ToggleButton';
import styles from './MainNavigation.module.css';

function MainNavigation() {
    const { language } = useContext(LanguageContext);

    const navbarTitles = {
        hu: {
            territoryTitle: 'Területünk',
            guestHouseTitle: 'Vendégház',
            contactTitle: 'Kapcsolat',
            pricesTitle: 'Árjegyzék',
            conditionsTitle: 'Feltételek',
            documentsTitle: 'Dokumentumok'
        },
        en: {
            territoryTitle: 'Territory',
            guestHouseTitle: 'Guesthouse',
            contactTitle: 'Contact',
            pricesTitle: 'Prices',
            conditionsTitle: 'Conditions',
            documentsTitle: 'Documents'
        },
        de: {
            territoryTitle: 'Territorium',
            guestHouseTitle: 'Gasthaus',
            contactTitle: 'Beziehung',
            pricesTitle: 'Preisliste',
            conditionsTitle: 'Bedingungen',
            documentsTitle: 'Unterlagen'
        }
    }

    // Toggle button implementation
    const [isToggled, setIsToggled] = useState(false);
    const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);

    const toggleLanguageDropdownHandler = () => {
        setIsLanguageDropdown(current => !current);
    }

    const closeLanguageDropdownHandler = () => {
        setIsLanguageDropdown(false);
    }

    const toggleHamburgerMenuHandler = () => {
        setIsToggled(current => !current);
    }

    const closeHamburgerMenuHandler = () => {
        setIsToggled(false);
        closeLanguageDropdownHandler();
    }

    const setActiveClass = ({ isActive }) => isActive ? styles.active : undefined;
    

    return <header className={styles.header}>
        <nav className={styles.navbar}>
            <Brand closeToggle={closeHamburgerMenuHandler} />
            <ToggleButton onToggleHamburgerMenu={toggleHamburgerMenuHandler} onCloseHambrugerMenu={closeHamburgerMenuHandler} />
            <div className={isToggled ? `${styles['navbar-links']} ${styles.active}` : styles['navbar-links']}>
                <ul>
                    <li className={styles['navbar__item']}>
                        <NavLink className={setActiveClass} to='/territory' onClick={closeHamburgerMenuHandler}>{navbarTitles[language].territoryTitle}</NavLink>
                    </li>
                    <li className={styles['navbar__item']}>
                        <NavLink className={setActiveClass} to='/prices' onClick={closeHamburgerMenuHandler}>{navbarTitles[language].pricesTitle}</NavLink>
                    </li>
                    <li className={styles['navbar__item']}>
                        <NavLink className={setActiveClass} to='/conditions' onClick={closeHamburgerMenuHandler}>{navbarTitles[language].conditionsTitle}</NavLink>
                    </li>
                    <li className={styles['navbar__item']}>
                        <NavLink className={setActiveClass} to='/guest-house' onClick={closeHamburgerMenuHandler}>{navbarTitles[language].guestHouseTitle}</NavLink>
                    </li>
                    <li className={styles['navbar__item']}>
                        <NavLink className={setActiveClass} to='/documents' onClick={closeHamburgerMenuHandler}>{navbarTitles[language].documentsTitle}</NavLink>
                    </li>
                    <li className={styles['navbar__item']}>
                        <NavLink className={setActiveClass} to='/contact' onClick={closeHamburgerMenuHandler}>{navbarTitles[language].contactTitle}</NavLink>
                    </li>
                    <li className={styles.language}>
                        <LanguageSelector onCloseHambrugerMenu={closeHamburgerMenuHandler} toggleLanguageDropdown={toggleLanguageDropdownHandler} onCloseLanguageDropdown={closeLanguageDropdownHandler} isLanguageDropdownOpen={isLanguageDropdown}/>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
}

export default MainNavigation;