import { useContext } from 'react';
import LanguageContext from '../../../context/language-context';
import Background from '../../UI/Background';
import Title from '../../UI/Title';
import styles from './ConditionsContent.module.css';

const ConditionsContent = props => {
    const {language} = useContext(LanguageContext);

    const content = {
        hu: {
            title: 'Feltételek',
            h2: 'Vadászati előkészületek:',
            h3: 'Magyarországi vadászathoz szükséges:',
            p1: 'Érvényes személyazonosító okmány (személyazonosító igazolvány, útlevél, …) - Fegyvertartási engedély - érvényes magyar vadászjegy vagy engedély - Vadászati szerződés - Meghívólevél',
            p2: 'Külföldi vendégnek szükség esetén a magyar vadászati engedélyt, biztosítást, a Vadásztársaság szerzi be.',
            h3_1: 'Vadászat után:',
            p3: 'A vadászati jegyzőkönyv (lőjegyzék) aláírása kizárja a későbbi reklamációt.',
            p4: 'Trófea vadnál +/- 15% eltérés szakmailag elfogadható',
            p5: 'Trófeákról kiállított vámpapírok (az EU tagállamokon kívüli vadászvendégeknél) és a fegyver behozatalai engedélyt a kilépésnél a vámnál le kell adni, különben 25% ÁFA megfizetése válik esedékessé!',
            h3_2: 'Vadászattal kapcsolatos kérdéseivel kérjük keresse kollegáinkat!'
        },
        en: {
            title: 'Conditions',
            h2: 'Hunting preparations:',
            h3: 'It is necessary for hunting in Hungary:',
            p1: 'Valid identity document (identity card, passport, ...) - Firearm license - valid Hungarian hunting license or license - Hunting contract - Letter of invitation',
            p2: 'For foreign guests, if necessary, the Hunting Association obtains a Hungarian hunting license and insurance.',
            h3_1: 'After hunting:',
            p3: 'The signature of the hunting report (shooting) is excluded by later complaints.',
            p4: 'The +/- 15% difference for trophy is professionally acceptable',
            p5: 'Trophy papers (at hunting guests outside the EU) and the importation of the weapon imports must be granted at the customs, otherwise 25% of VAT will be due!',
            h3_2: 'Please contact our colleagues with your hunting questions!'
        },
        de: {
            title: 'Bedingungen',
            h2: 'Jagdvorbereitungen:',
            h3: 'Es ist für die Jagd in Ungarn notwendig:',
            p1: 'Gültiges Ausweisdokument (Personalausweis, Reisepass, ...) - Waffenschein - gültiger ungarischer Jagdschein oder Jagdschein - Jagdvertrag - Einladungsschreiben',
            p2: 'Für ausländische Gäste besorgt der Jagdverband bei Bedarf einen ungarischen Jagdschein und eine Versicherung.',
            h3_1: 'Nach der Jagd:',
            p3: 'Die Unterschrift des Jagdberichts (Schießen) wird durch spätere Beschwerden ausgeschlossen.',
            p4: 'Der Differenz von +/- 15% für die Trophäe ist professionell akzeptabel',
            p5: 'Trophäenpapiere (bei Jagdgästen außerhalb der EU) und der Einfuhr der Waffenimporte müssen am Zoll gewährt werden, sonst werden 25% der Mehrwertsteuer fällig!',
            h3_2: 'Bitte kontaktieren Sie unsere Kollegen mit Ihren Jagdfragen!'
        }
    };


    const contentByLanguage = content[language];

    return <Background>
        <Title title={contentByLanguage.title} />
        <div className={styles.conditions}>
            <h2>{contentByLanguage.h2}</h2>
            <div>
                <h3>{contentByLanguage.h3}</h3>
                <p>{contentByLanguage.p1}</p>
                <p>{contentByLanguage.p2}</p>
                {/* <h3>{contentByLanguage.h3_1}</h3> */}
                {/* <p>{contentByLanguage.p3}</p> */}
                <p>{contentByLanguage.p4}</p>
                {/* <p>{contentByLanguage.p5}</p> */}
                <h3>{contentByLanguage.h3_2}</h3>
            </div>
        </div>
    </Background>
};

export default ConditionsContent;