import styles from './Prices.module.css';
import Card from '../UI/Card';
import PriceTable from './PriceTable';

const Prices = props => {
    return <Card className={`${styles.card} ${props.className}`}>
        <PriceTable headerItems={props.headerItems} items={props.items}/>
    </Card>
};

export default Prices;