import { useContext } from 'react';
import { Link } from 'react-router-dom';
import LanguageContext from '../../context/language-context';

import styles from './Brand.module.css';

function Brand(props) {
    const { language } = useContext(LanguageContext);

    const brand = {
        hu: {
            title: 'Tapsony és Vidéke',
            description: 'Petõfi Vadásztársaság'
        },
        en: {
            title: 'Tapsony and its countryside',
            description: 'Petõfi Hunting Company'
        },
        de: {
            title: 'Tapsony und seine Landschaft',
            description: 'Petõfi Jagdgesellschaft'
        }
    }

    return <div className={styles['site-title']} onClick={props.closeToggle}>
        <h2><Link to='/'>{brand[language].title}</Link></h2>
        <p>{brand[language].description}</p>
    </div>
}

export default Brand;