import Brand from '../Brand/Brand';
import styles from './Footer.module.css';

const Footer = props => {
    return <footer className={styles.footer}>
        <p>2023 ©</p>
        <Brand />
        
    </footer>
}

export default Footer;