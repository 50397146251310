import styles from './ToggleButton.module.css';

function ToggleButton(props) {

    return <button className={styles['toggle-button']} onClick={props.onToggleHamburgerMenu}>
        <span className={styles.bar}></span>
        <span className={styles.bar}></span>
        <span className={styles.bar}></span>
    </button>;
}

export default ToggleButton;