import { Fragment, useState } from 'react';
import { ColorRing } from 'react-loader-spinner';
import styles from './Image.module.css';

const Image = props => {
    const [loading, setLoading] = useState(true);

    const imageLoaded = () => {
        setLoading(false);
    }

    console.log(props.url);

    return <Fragment>
        <div className={styles['spinner-container']} style={{ display: loading ? "block" : "none" }}>
            <ColorRing />
        </div>
        <div className={styles['image-container']} style={{ display: loading ? "none" : "block" }}>
            <img
                src={props.url}
                onLoad={imageLoaded}
                className={styles.image}
                alt='map'
            />
        </div>
    </Fragment>
};

export default Image;