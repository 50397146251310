import { useContext } from 'react';
import LanguageContext from '../../../context/language-context';
import HuntingTimesList from '../../HuntingTimes/HuntingTimesList';
import Prices from '../../Prices/Prices';
import Background from '../../UI/Background';
import Card from '../../UI/Card';
import Title from '../../UI/Title';
import styles from './PricesContent.module.css';

const PricesContent = props => {
    const { language } = useContext(LanguageContext);

    const deerPrices = [
        {
            weight: 'Spíszer',
            price: '150',
            additional: '-'
        }, {
            weight: '< 3.00',
            price: '300',
            additional: '-'
        }, {
            weight: '3.00 - 3.99',
            price: '400',
            additional: '-'
        }, {
            weight: '4.0 - 4.99',
            price: '700',
            additional: '+4.0'
        }, {
            weight: '5.0 - 5.99',
            price: '1100',
            additional: '+6.0'
        }, {
            weight: '6.0 - 6.99',
            price: '1700',
            additional: '+8.0'
        }, {
            weight: '7.0 - 7.99',
            price: '2500',
            additional: '+10.0'
        }, {
            weight: '8.0 - 8.99',
            price: '3500',
            additional: '+13.0'
        }, {
            weight: '9.0 - 9.49',
            price: '4800',
            additional: '+18.0'
        }, {
            weight: '9.5 - 9.99',
            price: '5700',
            additional: '+25.0'
        }, {
            weight: '10.0 - 10.49',
            price: '6950',
            additional: '+35.0'
        }, {
            weight: '10.5 - 10.99',
            price: '8700',
            additional: '+50.0'
        }, {
            weight: '11.0',
            price: '11200',
            additional: '+50.0'
        }
    ]

    const content = {
        hu: {
            title: 'Árjegyzék',
            huntingTime: 'Vadászati idény',
            deer: {
                title: 'Gímszarvas',
                tableHeader1: 'Agancs tömeg (kg)',
                huntingTimes: [
                    {
                        title: 'Golyóérett bika',
                        start: 'Szeptember 1.',
                        end: 'Október 31.'
                    },
                    {
                        title: 'Selejt bika',
                        start: 'Szeptember 1.',
                        end: 'Január 31.'
                    },
                    {
                        title: 'Tehén',
                        start: 'Szeptember 1.',
                        end: 'Január 31.'
                    },
                    {
                        title: 'Ünő',
                        start: 'Szeptember 1.',
                        end: 'Február utolsó napja.'
                    },
                    {
                        title: 'Borjú',
                        start: 'Szeptember 1.',
                        end: 'Április 30.'
                    }],
                prices: deerPrices,
                tableHeader2: 'Tarvad €/db',
                tableHeader3: 'Elejtés',
                tableHeader4: 'Sebzés',
                additionalPrices: [
                    {
                        weight: 'Tehén, ünő',
                        price: '115',
                        additional: '55'
                    }, {
                        weight: 'Borjú',
                        price: '60',
                        additional: '30'
                    },
                ],
                p1: 'Időpont foglaláskor fizetendő előleg 10%-a a tervezett elejtési díjnak díjbekérő ellenében, ami a megrendelőnek felróható okból meghiúsult vadászat esetén a vadásztársaságot illeti meg, eredményes vadászat esetén az árban elszámolásra, eredménytelen vadászat esetén pedig visszafizetésre kerül.',
                p2: 'A fizetés alapját képezi a vármegyei bírálóbizottság által kiállított trófeabírálati lapon szereplő „24 órás tömeg”.',
                p3: 'Sebzés: Az elejtési díj 50 %-a. Alapul a hivatásos vadász által becsült trófeasúly szolgál.'
            },
            roe: {
                title: 'Őz',
                tableHeader1: 'Agancs tömeg (g)',
                huntingTimes: [
                    {
                        title: 'Őzbak',
                        start: 'Április 15.',
                        end: 'Szeptember 30.'
                    },
                    {
                        title: 'Suta, gida',
                        start: 'Október 1.',
                        end: 'Február utolsó napja'
                    }],
                prices: [
                    {
                        weight: '< 150',
                        price: '100',
                        additional: '-'
                    }, {
                        weight: '150 - 249',
                        price: '150',
                        additional: '-'
                    }, {
                        weight: '250',
                        price: '150',
                        additional: '+2.0'
                    }, {
                        weight: '300',
                        price: '250',
                        additional: '+5.0'
                    }, {
                        weight: '350',
                        price: '500',
                        additional: '+8.0'
                    }, {
                        weight: '400',
                        price: '900',
                        additional: '+12.0'
                    }, {
                        weight: '450',
                        price: '1500',
                        additional: '+30.0'
                    }, {
                        weight: '500',
                        price: '3000',
                        additional: '+40.0'
                    },
                ],
                p1: 'Sebzés: az elejtési díj 50% -a.'
            },
            boar: {
                title: 'Vaddisznó',
                tableHeader1: 'Agyar hossz (cm)',
                prices: [{
                    weight: '< 12',
                    price: '250'
                }, {
                    weight: '12 - 14.99',
                    price: '500'
                }, {
                    weight: '15 - 16.99',
                    price: '800'
                }, {
                    weight: '17 - 19.99',
                    price: '990'
                }, {
                    weight: '20 <',
                    price: '1200'
                }, {
                    weight: 'Koca',
                    price: '220'
                }, {
                    weight: 'Süldő',
                    price: '110'
                }, {
                    weight: 'Malac',
                    price: '60'
                },]
            }
        },
        en: {
            title: 'Prices',
            huntingTime: 'Hunting season',
            deer: {
                title: 'Red deer',
                tableHeader1: 'Antler mass (kg)',
                huntingTimes: [
                    {
                        title: 'Mature stag',
                        start: 'September 1.',
                        end: 'October 31.'
                    },
                    {
                        title: 'Cull stag',
                        start: 'September 1.',
                        end: 'January 31.'
                    },
                    {
                        title: 'Hind',
                        start: 'September 1.',
                        end: 'January 31.'
                    },
                    {
                        title: 'Hind calf',
                        start: 'September 1.',
                        end: 'Last day of February.'
                    },
                    {
                        title: 'Calf',
                        start: 'September 1.',
                        end: 'April 30.'
                    }],
                prices: deerPrices,
                tableHeader2: '',
                tableHeader3: '€ / Kill',
                tableHeader4: '€ / Damage',
                additionalPrices: [
                    {
                        weight: 'Hind, Hind calf',
                        price: '115',
                        additional: '55'
                    }, {
                        weight: 'Calf',
                        price: '60',
                        additional: '30'
                    },
                ],
                p1: 'An advance payment of 10% of the planned kill fee is payable at the time of reservation against the fee requester, which is due to the hunting company in the event of a failed hunt due to reasons attributable to the customer.',
                p2: 'The payment is based on the "24-hour crowd" on the trophy evaluation sheet issued by the county evaluation committee.',
                p3: 'Damage: 50% of the drop charge. The basis is the trophy weight estimated by the professional hunter.'
            },
            roe: {
                title: 'European roe deer',
                tableHeader1: 'Antler mass (g)',
                huntingTimes: [
                    {
                        title: 'Roebuck',
                        start: 'April 15.',
                        end: 'September 30.'
                    },
                    {
                        title: 'Roe doe, Roe kid',
                        start: 'October 1.',
                        end: 'Last day of February'
                    }],
                prices: [
                    {
                        weight: '< 150',
                        price: '100',
                        additional: '-'
                    }, {
                        weight: '150 - 249',
                        price: '150',
                        additional: '-'
                    }, {
                        weight: '250',
                        price: '150',
                        additional: '+2.0'
                    }, {
                        weight: '300',
                        price: '250',
                        additional: '+5.0'
                    }, {
                        weight: '350',
                        price: '500',
                        additional: '+8.0'
                    }, {
                        weight: '400',
                        price: '900',
                        additional: '+12.0'
                    }, {
                        weight: '450',
                        price: '1500',
                        additional: '+30.0'
                    }, {
                        weight: '500',
                        price: '3000',
                        additional: '+40.0'
                    },
                ],
                p1: 'Damage: 50% of the kill charge.'
            },
            boar: {
                title: 'Wild boar',
                tableHeader1: 'Length of tusk (cm)',
                prices: [{
                    weight: '< 12',
                    price: '250'
                }, {
                    weight: '12 - 14.99',
                    price: '500'
                }, {
                    weight: '15 - 16.99',
                    price: '800'
                }, {
                    weight: '17 - 19.99',
                    price: '990'
                }, {
                    weight: '20 <',
                    price: '1200'
                }, {
                    weight: 'Wild sow',
                    price: '220'
                }, {
                    weight: 'Yearling boar',
                    price: '110'
                }, {
                    weight: 'Squeaker',
                    price: '60'
                },]
            }
        },
        de: {
            title: 'Preisliste',
            huntingTime: 'Jagdsaison',
            deer: {
                title: 'Der Rothirsch',
                tableHeader1: 'Geweih Masse (kg)',
                huntingTimes: [
                    {
                        title: 'der abschussreifer Hirsch,',
                        start: 'September 1.',
                        end: 'Oktober 31.'
                    },
                    {
                        title: 'der Abschusshirsch',
                        start: 'September 1.',
                        end: 'Januar 31.'
                    },
                    {
                        title: 'das Rottier',
                        start: 'September 1.',
                        end: 'Januar 31.'
                    },
                    {
                        title: 'das Wildkalb',
                        start: 'September 1.',
                        end: 'Letzter Tag im Februar.'
                    },
                    {
                        title: 'das Kalb',
                        start: 'September 1.',
                        end: 'April 30.'
                    }],
                prices: deerPrices,
                tableHeader2: '',
                tableHeader3: '€ /Umbringen',
                tableHeader4: '€ / Schaden',
                additionalPrices: [
                    {
                        weight: 'das Rottier, das Schmaltier',
                        price: '115',
                        additional: '55'
                    }, {
                        weight: 'Borjú',
                        price: '60',
                        additional: '30'
                    },
                ],
                p1: 'Bei der Reservierung ist gegenüber dem Honorarantragsteller eine Vorauszahlung in Höhe von 10 % des geplanten Abschusshonorars zu entrichten, die dem Jagdunternehmen im Falle eines aus Gründen, die der Kunde zu vertreten hat, fehlgeschlagenen Jagden zusteht.',
                p2: 'Die Zahlung basiert auf der „24-Stunden-Menge“ auf dem Trophäenbewertungsbogen, der vom Bezirksbewertungsausschuss herausgegeben wird.',
                p3: 'Schaden: 50 % der Fallladung. Grundlage ist das vom Berufsjäger geschätzte Trophäengewicht.'
            },
            roe: {
                title: 'Das Reh',
                tableHeader1: 'Geweih Masse (g)',
                huntingTimes: [
                    {
                        title: 'der Rehbock',
                        start: 'April 15.',
                        end: 'September 30.'
                    },
                    {
                        title: 'die Ricke, das Rehkitz',
                        start: 'Oktober 1.',
                        end: 'Letzter Tag im Februar'
                    }],
                prices: [
                    {
                        weight: '< 150',
                        price: '100',
                        additional: '-'
                    }, {
                        weight: '150 - 249',
                        price: '150',
                        additional: '-'
                    }, {
                        weight: '250',
                        price: '150',
                        additional: '+2.0'
                    }, {
                        weight: '300',
                        price: '250',
                        additional: '+5.0'
                    }, {
                        weight: '350',
                        price: '500',
                        additional: '+8.0'
                    }, {
                        weight: '400',
                        price: '900',
                        additional: '+12.0'
                    }, {
                        weight: '450',
                        price: '1500',
                        additional: '+30.0'
                    }, {
                        weight: '500',
                        price: '3000',
                        additional: '+40.0'
                    },
                ],
                p1: 'Schaden: 50 % der Tötungsladung.'
            },
            boar: {
                title: 'Das Wildschwein',
                tableHeader1: 'Länge das Gewehr (cm)',
                prices: [{
                    weight: '< 12',
                    price: '250'
                }, {
                    weight: '12 - 14.99',
                    price: '500'
                }, {
                    weight: '15 - 16.99',
                    price: '800'
                }, {
                    weight: '17 - 19.99',
                    price: '990'
                }, {
                    weight: '20 <',
                    price: '1200'
                }, {
                    weight: 'die Bache',
                    price: '220'
                }, {
                    weight: 'der Überläuferkeiler',
                    price: '110'
                }, {
                    weight: 'der Frischling',
                    price: '60'
                },]
            }
        }
    };

    const contentByLanguage = content[language];

    return <Background>
        <Title title={contentByLanguage.title} />
        <div className={styles.prices}>
            <div>
                <h2 className={styles['animal__title']}>{contentByLanguage.deer.title}</h2>
                <HuntingTimesList title={contentByLanguage.huntingTime} huntingTimes={contentByLanguage.deer.huntingTimes} />
                <Prices headerItems={[contentByLanguage.deer.tableHeader1, '€', '€/10g']} items={contentByLanguage.deer.prices} />
                <Card className={styles.card}>
                    <p>{contentByLanguage.deer.p1}</p>
                    <p>{contentByLanguage.deer.p2}</p>
                    <p>{contentByLanguage.deer.p3}</p>
                </Card>
                {/* <Prices headerItems={[contentByLanguage.deer.tableHeader2, contentByLanguage.deer.tableHeader3, contentByLanguage.deer.tableHeader4]}
                    items={contentByLanguage.deer.additionalPrices} /> */}
            </div>
            {/* <div>
                <h2 className={styles['animal__title']}>{contentByLanguage.roe.title}</h2>
                <HuntingTimesList title={contentByLanguage.huntingTime} huntingTimes={contentByLanguage.roe.huntingTimes} />
                <Prices headerItems={[contentByLanguage.roe.tableHeader1, '€', '€/1g']} items={contentByLanguage.roe.prices} />
                <Card className={styles.card}>
                    <p>{contentByLanguage.roe.p1}</p>
                </Card>
            </div> */}
            {/* <div>
                <h2 className={styles['animal__title']}>{contentByLanguage.boar.title}</h2>
                <Prices headerItems={[contentByLanguage.boar.tableHeader1, '€']} items={contentByLanguage.boar.prices} />
            </div> */}
        </div>

    </Background>
};

export default PricesContent